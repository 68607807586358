import { Analytics } from "firebase/analytics";
import { useAuth } from "react-oidc-context";
import Home from './components/Home';
import LoadingSpinner from "./components/LoadingSpinner"
import { logEvent } from "firebase/analytics";


function App(analytics: Analytics) {
  const auth = useAuth();
  const props = {auth: auth, analytics: analytics};
  switch (auth.activeNavigator) {
    case "signinSilent":
        return <div>Signing you in...</div>;
    case "signoutRedirect":
        return <div>Signing you out...</div>;
  } 
  if (auth.isLoading) {
    return (
      <LoadingSpinner/>
    );
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    logEvent(analytics, 'user_authenticated');
        return (
      <Home {...props}/>
    );
  }
  logEvent(analytics, 'auth_redirect');
  auth.signinRedirect();

  return <div />;
}

export default App;