export const selectElementContents = (el, eventFunc) => {
    var body = document.body, range, sel;
    if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
            range.selectNodeContents(el);
            sel.addRange(range);
        } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
        }
        document.execCommand("copy");

    } else if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(el);
        range.select();
        range.execCommand("Copy");
    }
    eventFunc(el.id);
}

export const bindButtons = (eventFunc) => 
{
    let anchors = document.getElementsByClassName("btn-copy");
    for (var i = 0; i < anchors.length; i++) {   
        let divId = anchors[i].id.replace("btn-", "");
        let div = document.getElementById(divId);
        anchors[i].onclick = function() { selectElementContents( div, eventFunc);}
    }
}