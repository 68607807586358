export default function Instructions() {
    return (
        <div className="wrapper">
        <div className="instructions" id="instructions" data-testid="instructions">
            <h2>Instructions</h2>
            <details>
                <summary>For Windows users</summary>
                <ol>
                    <li>
                        <p>Copy Default Signature from this site</p>
                        <br />
                        <img src="/screens_win/step0.png" alt="" />
                    </li>
                    <li>
                        <p>Open Outlook and choose "New Email"</p>
                        <img src="/screens_win/step1.png" alt="" />
                    </li>
                    <li>
                        <p>Open "Signature" dropdown menu and then choose "Signatures..."</p>
                        <img src="/screens_win/step2.png" alt="" />
                    </li>
                    <li>
                        <p>Click "New", enter "Default" name and click "OK"</p>
                        <img src="/screens_win/step3.png" alt="" />
                    </li>
                    <li>
                        <p>Paste your copied Default Signature content into the editing field and click "Save"</p>
                        <img src="/screens_win/step4.png"  alt="" />
                    </li>
                    <li>
                        <p>For "New messages" choose "Default" signature that you have just created. <br />Click "OK" to save this setting.</p>
                        <img src="/screens_win/step5.png"  alt="" />
                    </li>
                    <li>
                        <p>Repeat 1-6 steps, this time for the Short signature.<br />Remember to:</p>
                        <ul>
                            <li>Copy the "Short signature" from this site</li>
                            <li>For "Replies/forwards" choose "Short" signature that you have just created.</li>
                            <li>Save your settings by clicking "OK".</li>
                        </ul> 
                        <img src="/screens_win/step6.png"  alt="" />
                    </li>
                    <li>
                        <p>Enjoy your signature!</p>
                        <img src="/screens_win/step7.png"  alt="" />
                    </li>
                </ol>
            </details>
            <details>
                <summary>For Mac users</summary>
                <ol>
                    <li>
                        <p>Copy Default Signature from this site</p>
                        <br />
                        <img src="/screens_mac/1-mac-instructions.png"  alt="" />
                    </li>
                    <li>
                        <p>Open Outlook and choose "New Message"</p>
                        <img src="/screens_mac/2-mac-instructions.png"  alt="" />
                    </li>
                    <li>
                        <p>Open "Signature" dropdown menu and then choose "Manage Signatures"</p>
                        <img src="/screens_mac/3-mac-instructions.png"  alt="" />
                    </li>
                    <li>
                        <p>Choose "+" to create a new signature</p>
                        <img src="/screens_mac/4-mac-instructions.png"  alt="" />
                    </li>
                    <li>
                        <p>Enter "Default" name for your signature, paste the copied Default Signature content into the editing field and click "Save" icon</p>
                        <img src="/screens_mac/5-mac-instructions.png"  alt="" />
                    </li>
                    <li>
                        <p>For "New messages" choose "Default" signature that you have just created.</p>
                        <img src="/screens_mac/6-mac-instructions.png"  alt="" />
                    </li>
                    <li>
                        <p>Repeat 1-6 steps, this time for the Short signature.<br />Remember to:</p>
                        <ul>
                            <li>Copy the "Short signature" from this site</li>
                            <li>For "Replies/forwards" choose "Short" signature that you have just created.</li>
                        </ul>
                        <img src="/screens_mac/7-mac-instructions.png"  alt="" />
                    </li>
                    <li>
                        <p>Enjoy your signature!</p>
                        <img src="/screens_mac/8-mac-instructions.png"  alt="" />
                    </li>
                </ol>
            </details>
        </div>
        </div>
    );
}