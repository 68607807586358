import { E164Number } from 'libphonenumber-js/core';
import './Home.css';
import { signShort, signExt, signFull, signGerman, signGermanExt } from "./signatures";
import convertHtmlToReact from '@hedgedoc/html-to-react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'
import { useEffect, useState } from "react";
import { createRoot } from 'react-dom/client';
import { bindButtons } from "./utils"
import Instructions from './Instructions';
import { AuthContextProps } from 'react-oidc-context';
import { Analytics, logEvent } from 'firebase/analytics';


export default function Home(props:{analytics: Analytics, auth: AuthContextProps | null | undefined}) {
  const [phoneValue, setValue] = useState<string>();
  const isGerman = props?.auth?.user?.profile.officelocation === "Berlin";
  const sublogger = {"sub": props?.auth?.user?.profile.sub, "dep": props?.auth?.user?.profile.department};

  useEffect(() => { // componentDidMount
    renderSignatures();
    bindButtons((elId: string)=>localLogEvent("sig_copy", {"element_id": elId, ...sublogger}));
    localLogEvent("home_did_mount");
  }, []);

  function localLogEvent (eventName: string, payload?: {
    [key: string]: any;
  } | undefined) 
  {
      logEvent(props.analytics, eventName, payload ?? sublogger)
  }

  function setValueAndRender(value?: E164Number | null | undefined): void {
    let inp = document.getElementsByClassName('form-control')[0] as HTMLInputElement
    let formatted = inp.value??"";
    if (formatted.length<=3) formatted = "";
    if (formatted.length>=6) localLogEvent("home_phone_render")

    renderSignatures(formatted);
    
    setValue(inp.value);
  }

  function getSignature(template: string, value?: E164Number | undefined) {
    let renderedSign = template.toString().replace('{Name}', props?.auth?.user?.profile.name as string);
    renderedSign = renderedSign.replace('{Position}', props?.auth?.user?.profile.jobtitle as string);
    renderedSign = renderedSign.replaceAll('{Email}', props?.auth?.user?.profile.email as string);
    renderedSign = renderedSign.replaceAll('{Phone}', value as string);
    renderedSign = renderedSign.replaceAll('{PhoneOn}', value ? "block" : "none");
    return convertHtmlToReact(renderedSign);
  }

  function renderSignatures(value?: E164Number | undefined) {
    createRoot(document.getElementById('sExt')!).render(getSignature(isGerman ? signGermanExt : signExt, value));  
    createRoot(document.getElementById('sShort')!).render(getSignature(isGerman ? signGerman : signShort, value));  
    if (!isGerman)
    {
      createRoot(document.getElementById('sFull')!).render(getSignature(signFull, value));  
    }
  }

  return (
    <section className="text-gray-600 body-font relative" data-testid="main">
    <div className="container px-5 py-24 mx-auto">
      <div className="flex flex-col text-center mb-12">
        <h1 className="sm:text-2xl text-2xl font-medium title-font mb-4 text-gray-900">
          Welcome {props?.auth?.user?.profile.given_name}.
        </h1>
        <div className="form">
          <p>Your e-mail signatures are generated below and ready to use.</p>
          
          <div className="intro">
              <ol>
                  <li>Add a phone number if you'd like it included:
                  <PhoneInput disableCountryCode={false}
                  data-testid="phone-input" country={'no'} onlyCountries={['no', 'pl', 'de', 'dk']} placeholder="Enter your phone number" 
                  value={phoneValue} onChange={setValueAndRender}/>
                  </li>
                  <li>Copy the signature</li>
                  <li>Paste it into Outlook. <a href="#instructions">See Instructions</a></li>
              </ol>
          </div>
        </div>
      </div>

      <div className="grid-container" id='sig-container'>
        <div className="grid-child signature__bar">
          <h3>Default signature</h3>
          <button data-testid="copy-btn" id="btn-sExt" className="btn-copy">Copy</button>
        </div>
        <div data-testid="sign-content" className="grid-child" id='sExt'></div>
        <div className="grid-child signature__bar">
          <h3>Short signature</h3>
          <button data-testid="copy-btn" id="btn-sShort" className="btn-copy">Copy</button>
        </div>
        <div data-testid="sign-content" className="grid-child" id='sShort'></div>
        <div className="grid-child signature__bar"  style={{display: isGerman ? "none" : "flex"}}>
          <h3>Full signature</h3>
          <button data-testid="copy-btn" id="btn-sFull" className="btn-copy">Copy</button>
        </div>
        <div data-testid="sign-content" className="grid-child" id='sFull'></div>
      </div>
    </div>
    <Instructions />
  </section>

  );
}
