const signGermanExt = `<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=432
 style='width:4.5in;mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:
 0in 0in 0in 0in'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:16.0pt'><b><span
  style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>{Name}<o:p></o:p></span></b></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:1'>
  <td style='padding:0in 0in 7.5pt 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:16.0pt'><span
  style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>{Position}<o:p></o:p></span></p>
  </td>
 </tr>
<tr style='mso-yfti-irow:2'>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>Forte Digital GmbH<o:p></o:p></span></p>
  </td>
 </tr>
  <tr style='mso-yfti-irow:2'>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>Köpenicker Str. 122<o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:2'>
 <td style='padding:0in 0in 0in 0in'>
 <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>D–10179 Berlin<o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:2'>
 <td style='padding:0in 0in 9.5pt 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>Germany<o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:2'>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424;display:{PhoneOn}'>Phone: {Phone}<o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:3'>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>Mail: <a
  href="mailto:{Email}"><span style='color:#242424;
  text-decoration:none;text-underline:none'>{Email}</span></a><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:4'>
  <td style='padding:0in 0in 7.5pt 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:16.0pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>Web: <a href="https://fortedigital.de/"><span
  style='color:#242424'>fortedigital.de</span> </a><o:p></o:p></span></p>
  </td>
 <tr style='mso-yfti-irow:5'>
  <td style='padding:9.5pt 0in 4.5pt 0in'>
  <p class=MsoNormal style='line-height:16.0pt'>
  <a
  href="https://www.linkedin.com/company/forte-digital-central-europe/"><span style='color:
  windowtext;mso-no-proof:yes;text-decoration:none;text-underline:none'><!--[if gte vml 1]><v:shape
   id="_x0000_i1029" type="#_x0000_t75" alt="Linkedin" style='width:11.4pt;
   height:11.4pt'>
   <v:imagedata src="Forte%20Full_files/image005.png" o:title="linkedin"/>
  </v:shape><![endif]--><![if !vml]><img border=0 width=15 height=15
  src="Forte%20Full_files/image005.png" alt=Linkedin v:shapes="_x0000_i1029"><![endif]></span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
  href="https://www.facebook.com/fortedigitalno"><span style='color:windowtext;
  mso-no-proof:yes;text-decoration:none;text-underline:none'><!--[if gte vml 1]><v:shapetype
   id="_x0000_t75" coordsize="21600,21600" o:spt="75" o:preferrelative="t"
   path="m@4@5l@4@11@9@11@9@5xe" filled="f" stroked="f">
   <v:stroke joinstyle="miter"/>
   <v:formulas>
    <v:f eqn="if lineDrawn pixelLineWidth 0"/>
    <v:f eqn="sum @0 1 0"/>
    <v:f eqn="sum 0 0 @1"/>
    <v:f eqn="prod @2 1 2"/>
    <v:f eqn="prod @3 21600 pixelWidth"/>
    <v:f eqn="prod @3 21600 pixelHeight"/>
    <v:f eqn="sum @0 0 1"/>
    <v:f eqn="prod @6 1 2"/>
    <v:f eqn="prod @7 21600 pixelWidth"/>
    <v:f eqn="sum @8 21600 0"/>
    <v:f eqn="prod @7 21600 pixelHeight"/>
    <v:f eqn="sum @10 21600 0"/>
   </v:formulas>
   <v:path o:extrusionok="f" gradientshapeok="t" o:connecttype="rect"/>
   <o:lock v:ext="edit" aspectratio="t"/>
  </v:shapetype><v:shape id="_x0000_i1025" type="#_x0000_t75" alt="Facebook"
   style='width:11.4pt;height:11.4pt'>
   <v:imagedata src="Forte%20Full_files/image001.png" o:title="facebook"/>
  </v:shape><![endif]--><![if !vml]><img border=0 width=15 height=15
  src="Forte%20Full_files/image001.png" alt=Facebook v:shapes="_x0000_i1025"><![endif]></span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
  href="https://twitter.com/ForteDigitalNo"><span style='color:windowtext;
  mso-no-proof:yes;text-decoration:none;text-underline:none'><!--[if gte vml 1]><v:shape
   id="_x0000_i1026" type="#_x0000_t75" alt="Twitter" style='width:11.4pt;
   height:11.4pt'>
   <v:imagedata src="Forte%20Full_files/image002.png" o:title="twitter"/>
  </v:shape><![endif]--><![if !vml]><img border=0 width=15 height=15
  src="Forte%20Full_files/image002.png" alt=Twitter v:shapes="_x0000_i1026"><![endif]></span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
  href="https://www.instagram.com/forte_digital/"><span style='color:windowtext;
  mso-no-proof:yes;text-decoration:none;text-underline:none'><!--[if gte vml 1]><v:shape
   id="_x0000_i1027" type="#_x0000_t75" alt="Instagram" style='width:11.4pt;
   height:11.4pt'>
   <v:imagedata src="Forte%20Full_files/image003.png" o:title="instagram"/>
  </v:shape><![endif]--><![if !vml]><img border=0 width=15 height=15
  src="Forte%20Full_files/image003.png" alt=Instagram v:shapes="_x0000_i1027"><![endif]></span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
  href="https://www.youtube.com/c/ForteDigital"><span style='color:windowtext;
  mso-no-proof:yes;text-decoration:none;text-underline:none'><!--[if gte vml 1]><v:shape
   id="_x0000_i1028" type="#_x0000_t75" alt="Youtube" style='width:11.4pt;
   height:11.4pt'>
   <v:imagedata src="Forte%20Full_files/image004.png" o:title="youtube"/>
  </v:shape><![endif]--><![if !vml]><img border=0 width=15 height=15
  src="Forte%20Full_files/image004.png" alt=Youtube v:shapes="_x0000_i1028"><![endif]></span></a><span
  style='font-size:10.0pt'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:2'>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>Registered Court: AG Charlottenburg, HRB 239113,<o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:2'>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>VAT DE353123020<o:p></o:p></span></p>
  </td>
 </tr>
  <tr style='mso-yfti-irow:2'>
  <td style='padding:0in 0in 9.5pt 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>Managing Partners: Joachim Bader, Christof Zahneissen<o:p></o:p></span></p>
  </td>
 </tr>
</table>`;

const signGerman = `
<table border="0" cellpadding="0" cellspacing="0" class="MsoNormalTable" style="width:4.5in;mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:
0in 0in 0in 0in" width="432">
               <tbody>
               <tr style="mso-yfti-irow:0;mso-yfti-firstrow:yes">
                   <td style="padding:0in 0in 0in 0in">
                       <p class="MsoNormal" style="margin:.1pt;line-height:16pt"><b><span style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
 &quot;Times New Roman&quot;;color:#242424">{Name}<o:p></o:p></span></b></p>
                   </td>
               </tr>
               <tr style="mso-yfti-irow:1">
                   <td style="padding:0in 0in 7.5pt 0in">
                       <p class="MsoNormal" style="margin:.1pt;line-height:16pt"><span style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
 &quot;Times New Roman&quot;;color:#242424">{Position}<o:p></o:p></span></p>
                   </td>
               </tr>
                    <tr style="mso-yfti-irow:2">
                        <td style="padding:0in 0in 0in 0in">
                            <p class="MsoNormal" style="margin:.1pt;line-height:13.5pt"><span style="font-size:8.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
      &quot;Times New Roman&quot;;color:#242424;display:{PhoneOn}">Phone: {Phone}<o:p></o:p></span></p>
                        </td>
                    </tr>
                    <tr style="mso-yfti-irow:3">
                        <td style="padding:0in 0in 0in 0in">
                            <p class="MsoNormal" style="margin:.1pt;line-height:13.5pt"><span style="font-size:8.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
      &quot;Times New Roman&quot;;color:#242424">Mail: <a href="mailto:{Email}"><span style="color:#242424;
      text-decoration:none;text-underline:none">{Email}</span></a><o:p></o:p></span></p>
                        </td>
                    </tr>
                    <tr style="mso-yfti-irow:4;mso-yfti-lastrow:yes">
                        <td style="padding:0in 0in 7.5pt 0in">
                            <p class="MsoNormal" style="margin:.1pt;line-height:16pt"><span style="font-size:8.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
      &quot;Times New Roman&quot;;color:#242424">Web: <a href="https://fortedigital.de/"><span style="color:#242424">fortedigital.de</span></a><o:p></o:p></span>
                            </p>
                        </td>
                    </tr>
               </tbody>
          </table>
`
const signShort = `
        <table border="0" cellpadding="0" cellspacing="0" class="MsoNormalTable" style="width:4.5in;mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:
     0in 0in 0in 0in" width="432">
                    <tbody>
                    <tr style="mso-yfti-irow:0;mso-yfti-firstrow:yes">
                        <td style="padding:0in 0in 0in 0in">
                            <p class="MsoNormal" style="margin:.1pt;line-height:16pt"><b><span style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
      &quot;Times New Roman&quot;;color:#242424">{Name}<o:p></o:p></span></b></p>
                        </td>
                    </tr>
                    <tr style="mso-yfti-irow:1">
                        <td style="padding:0in 0in 7.5pt 0in">
                            <p class="MsoNormal" style="margin:.1pt;line-height:16pt"><span style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
      &quot;Times New Roman&quot;;color:#242424">{Position}<o:p></o:p></span></p>
                        </td>
                    </tr>
                    <tr style="mso-yfti-irow:2">
                        <td style="padding:0in 0in 0in 0in">
                            <p class="MsoNormal" style="margin:.1pt;line-height:13.5pt"><span style="font-size:8.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
      &quot;Times New Roman&quot;;color:#242424;display:{PhoneOn}">T: {Phone}<o:p></o:p></span></p>
                        </td>
                    </tr>
                    <tr style="mso-yfti-irow:3">
                        <td style="padding:0in 0in 7.5pt 0in">
                            <p class="MsoNormal" style="margin:.1pt;line-height:13.5pt"><span style="font-size:8.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
      &quot;Times New Roman&quot;;color:#242424">E: <a href="mailto:{Email}"><span style="color:#242424;
      text-decoration:none;text-underline:none">{Email}</span></a><o:p></o:p></span></p>
                        </td>
                    </tr>
                    <tr style="mso-yfti-irow:4;mso-yfti-lastrow:yes">
                        <td style="padding:7.5pt 0in 0in 0in">
                            <p class="MsoNormal" style="margin:.1pt;line-height:16pt"><span style="font-size:8.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
      &quot;Times New Roman&quot;;color:#242424"><a href="https://fortedigital.no/"><span style="color:#242424">fortedigital.no</span></a><o:p></o:p></span>
                            </p>
                        </td>
                    </tr>
                    </tbody>
               </table>
  `
;

const signExt = `<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=432
 style='width:4.5in;mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:
 0in 0in 0in 0in'>
 <tbody><tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:16.0pt'><b><span
  style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>{Name}<o:p></o:p></span></b></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:1'>
  <td style='padding:0in 0in 7.5pt 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:16.0pt'><span
  style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>{Position}<o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:2'>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424;display:{PhoneOn}'>T: {Phone}<o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:3'>
  <td style='padding:0in 0in 7.5pt 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>E: <a
  href="mailto:{Email}"><span style='color:#242424;
  text-decoration:none;text-underline:none'>{Email}</span></a><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:4'>
  <td style='padding:7.5pt 0in 7.5pt 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:16.0pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'><a href="https://fortedigital.no/"><span
  style='color:#242424'>fortedigital.no</span> </a><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:5'>
  <td style='padding:7.5pt 0in 7.5pt 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:17.0pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;color:#242424'><a
  href="https://www.facebook.com/fortedigitalno"><span style='color:#242424'>Facebook</span></a>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
  href="https://www.instagram.com/forte_digital/"><span style='color:#242424'>Instagram</span></a>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a
  href="https://www.linkedin.com/company/forte_digital"><span style='color:
  #242424'>Linkedin</span></a> <o:p></o:p></span></p>
  </td>
 </tr></tbody>
</table>`;

const signFull = `<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=432
 style='width:4.5in;mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:
 0in 0in 0in 0in'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:16.0pt'><b><span
  style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>{Name}<o:p></o:p></span></b></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:1'>
  <td style='padding:0in 0in 7.5pt 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:16.0pt'><span
  style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>{Position}<o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:2'>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424;display:{PhoneOn}'>T: {Phone}<o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:3'>
  <td style='padding:0in 0in 7.5pt 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:13.5pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'>E: <a
  href="mailto:{Email}"><span style='color:#242424;
  text-decoration:none;text-underline:none'>{Email}</span></a><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:4'>
  <td style='padding:7.5pt 0in 7.5pt 0in'>
  <p class=MsoNormal style='margin:.1pt;line-height:16.0pt'><span
  style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
  "Times New Roman";color:#242424'><a href="https://fortedigital.no/"><span
  style='color:#242424'>fortedigital.no</span></a><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:5'>
  <td style='padding:9.5pt 0in 7.5pt 0in'>
  <p class=MsoNormal style='line-height:16.0pt'><a
  href="https://www.facebook.com/fortedigitalno"><span style='color:windowtext;
  mso-no-proof:yes;text-decoration:none;text-underline:none'><!--[if gte vml 1]><v:shapetype
   id="_x0000_t75" coordsize="21600,21600" o:spt="75" o:preferrelative="t"
   path="m@4@5l@4@11@9@11@9@5xe" filled="f" stroked="f">
   <v:stroke joinstyle="miter"/>
   <v:formulas>
    <v:f eqn="if lineDrawn pixelLineWidth 0"/>
    <v:f eqn="sum @0 1 0"/>
    <v:f eqn="sum 0 0 @1"/>
    <v:f eqn="prod @2 1 2"/>
    <v:f eqn="prod @3 21600 pixelWidth"/>
    <v:f eqn="prod @3 21600 pixelHeight"/>
    <v:f eqn="sum @0 0 1"/>
    <v:f eqn="prod @6 1 2"/>
    <v:f eqn="prod @7 21600 pixelWidth"/>
    <v:f eqn="sum @8 21600 0"/>
    <v:f eqn="prod @7 21600 pixelHeight"/>
    <v:f eqn="sum @10 21600 0"/>
   </v:formulas>
   <v:path o:extrusionok="f" gradientshapeok="t" o:connecttype="rect"/>
   <o:lock v:ext="edit" aspectratio="t"/>
  </v:shapetype><v:shape id="_x0000_i1025" type="#_x0000_t75" alt="Facebook"
   style='width:11.4pt;height:11.4pt'>
   <v:imagedata src="Forte%20Full_files/image001.png" o:title="facebook"/>
  </v:shape><![endif]--><![if !vml]><img border=0 width=15 height=15
  src="Forte%20Full_files/image001.png" alt=Facebook v:shapes="_x0000_i1025"><![endif]></span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
  href="https://twitter.com/ForteDigitalNo"><span style='color:windowtext;
  mso-no-proof:yes;text-decoration:none;text-underline:none'><!--[if gte vml 1]><v:shape
   id="_x0000_i1026" type="#_x0000_t75" alt="Twitter" style='width:11.4pt;
   height:11.4pt'>
   <v:imagedata src="Forte%20Full_files/image002.png" o:title="twitter"/>
  </v:shape><![endif]--><![if !vml]><img border=0 width=15 height=15
  src="Forte%20Full_files/image002.png" alt=Twitter v:shapes="_x0000_i1026"><![endif]></span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
  href="https://www.instagram.com/forte_digital/"><span style='color:windowtext;
  mso-no-proof:yes;text-decoration:none;text-underline:none'><!--[if gte vml 1]><v:shape
   id="_x0000_i1027" type="#_x0000_t75" alt="Instagram" style='width:11.4pt;
   height:11.4pt'>
   <v:imagedata src="Forte%20Full_files/image003.png" o:title="instagram"/>
  </v:shape><![endif]--><![if !vml]><img border=0 width=15 height=15
  src="Forte%20Full_files/image003.png" alt=Instagram v:shapes="_x0000_i1027"><![endif]></span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
  href="https://www.youtube.com/c/ForteDigital"><span style='color:windowtext;
  mso-no-proof:yes;text-decoration:none;text-underline:none'><!--[if gte vml 1]><v:shape
   id="_x0000_i1028" type="#_x0000_t75" alt="Youtube" style='width:11.4pt;
   height:11.4pt'>
   <v:imagedata src="Forte%20Full_files/image004.png" o:title="youtube"/>
  </v:shape><![endif]--><![if !vml]><img border=0 width=15 height=15
  src="Forte%20Full_files/image004.png" alt=Youtube v:shapes="_x0000_i1028"><![endif]></span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
  href="https://www.linkedin.com/company/forte_digital"><span style='color:
  windowtext;mso-no-proof:yes;text-decoration:none;text-underline:none'><!--[if gte vml 1]><v:shape
   id="_x0000_i1029" type="#_x0000_t75" alt="Linkedin" style='width:11.4pt;
   height:11.4pt'>
   <v:imagedata src="Forte%20Full_files/image005.png" o:title="linkedin"/>
  </v:shape><![endif]--><![if !vml]><img border=0 width=15 height=15
  src="Forte%20Full_files/image005.png" alt=Linkedin v:shapes="_x0000_i1029"><![endif]></span></a><span
  style='font-size:10.0pt'><o:p></o:p></span></p>
  </td>
 </tr>
</table>`;

export  { signShort, signExt, signFull, signGerman, signGermanExt };