import { User } from 'oidc-client-ts';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from "react-oidc-context";
import App from './App';
import Header from './components/Header';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import configData from "./configuration.json";


// Initialize Firebase
const app = initializeApp(configData.firebaseConfig);
const analytics = getAnalytics(app);

const signinCallback = (_user: User | void): void => {
  window.history.replaceState(
      {},
      document.title,
      window.location.pathname
  )
}
const oidcConfig = {
  authority: configData.oidc.authority,
  client_id: configData.oidc.client_id,
  redirect_uri: `${process.env.REACT_APP_APP_URL}`,
  loadUserInfo: true,
  onSigninCallback: signinCallback
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Header />
    <AuthProvider {...oidcConfig}>
      <App {...analytics}/>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();